<template>
  <div>
    <el-button @click="resetDateFilter">清除日期过滤器</el-button>
    <el-button @click="clearFilter">清除所有过滤器</el-button>
    <el-table :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="userconfig" label="账号"> </el-table-column>
      <el-table-column prop="type" label="状态" width="120"> </el-table-column>
      <el-table-column prop="mac" label="绑定设备号"> </el-table-column>
      <!-- <el-table-column prop="address" label="等级"> </el-table-column>
    <el-table-column prop="address" label="金币"> </el-table-column> -->
      <el-table-column fixed="right" label="操作" width="220">
        <template #default="scope">
          <el-button
            @click="handleClick(scope.row)"
            type="danger"
            size="small"
            plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getMhUser, deleteMhUser } from "@/api/user";
export default {
  data() {
    return {
      tableData: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    init() {
      getMhUser({ userid: this.$store.state.loginName.id }).then((res) => {
        if (res.status === 200) {
          this.tableData = res.data;
        }
      });
    },
    handleClick(item) {
      deleteMhUser({ id: item.id }).then((res) => {
        if (res.status === 200) {
          this.tableData.map((v, index) => {
            if (v.id === item.id) {
              this.tableData.splice(index, 1);
            }
          });
          this.$message.success({
            message: "导入成功！",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style>
</style>